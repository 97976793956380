import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import qs from 'qs'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import { getArticlePath } from 'lib/contentful/articles'
import { ARTICLE_TYPES } from 'lib/contentful/constants'
import { SHARE_IMAGE_DIMENSIONS } from 'lib/sharing/constants'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import { ROOT_URL } from 'config'
import { LIVE_PRODUCTS } from 'lib/products/constants'

import HelpArticleContent from './HelpArticleContent'
import { SECTION_TYPES } from '../constants'
import {
  parseStructuredData,
  getStructuredDataHowToSteps,
  getStructuredDataBreadcrumbs,
} from '../helpers'

const SHARE_IMAGE_PARAMS = {
  w: SHARE_IMAGE_DIMENSIONS.WIDTH,
  h: SHARE_IMAGE_DIMENSIONS.HEIGHT,
  fit: 'pad',
  bg: `rgb:${COLOR.ACCENT.PRIMARY.slice(1)}`,
}

const HelpArticle = ({ data: { article, guide, nextArticleInGuide } }) => {
  const url = `${ROOT_URL}${getArticlePath(article.slug, ARTICLE_TYPES.HELP)}`

  const shareImageUrl =
    article.image &&
    article.image.file &&
    `https:${article.image.file.url}?${qs.stringify(SHARE_IMAGE_PARAMS)}`

  return (
    <>
      <Wrapper
        style={{
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <JSONLD
          data={[
            {
              '@context': 'http://schema.org',
              '@type': 'BlogPosting',
              headline: article.title,
              description: article?.description?.description,
              image: article.image &&
                article.image.file && {
                  '@type': 'imageObject',
                  url: shareImageUrl,
                  width: SHARE_IMAGE_DIMENSIONS.WIDTH,
                  height: SHARE_IMAGE_DIMENSIONS.HEIGHT,
                },
              url,
              mainEntityOfPage: url,
              publisher: FAREWILL_JSONLD,
              author: article.author && {
                '@type': 'Person',
                name: article.author.name,
              },
              dateCreated: article.publishedAt,
              datePublished: article.publishedAt,
              dateModified: article.reviewedAt || article.updatedAt,
              ...parseStructuredData(article.structuredDataMain),
            },
            getStructuredDataBreadcrumbs(guide, article.title),
            getStructuredDataHowToSteps(article, url),
            parseStructuredData(article.structuredDataAdditionalObject),
          ]}
        />
        <DefaultLayout
          title={article.metaTitle || article.title}
          description={
            article?.metaDescription?.metaDescription ||
            article?.description?.description
          }
          shareImage={shareImageUrl}
          meta={
            article.author
              ? [
                  {
                    property: 'og:author',
                    content: article.author.name,
                  },
                ]
              : []
          }
        >
          <HelpArticleContent data={{ article, guide, nextArticleInGuide }} />
        </DefaultLayout>
      </Wrapper>
    </>
  )
}

HelpArticle.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string.isRequired,
      }),
      articleCategories: PropTypes.arrayOf(
        PropTypes.shape({
          shortTitle: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.shape({
        metaDescription: PropTypes.string.isRequired,
      }),
      widget: PropTypes.shape({
        type: PropTypes.string,
      }),
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
      }),
      product: PropTypes.oneOf(LIVE_PRODUCTS).isRequired,
      publishedAt: PropTypes.string.isRequired,
      reviewedAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      richContent: PropTypes.shape({
        raw: PropTypes.string.isRequired,
      }),
      structuredDataMain: PropTypes.shape({
        internal: PropTypes.shape({
          content: PropTypes.string.isRequired,
        }),
      }).isRequired,
      structuredDataAdditionalObject: PropTypes.shape({
        internal: PropTypes.shape({
          content: PropTypes.string.isRequired,
        }),
      }).isRequired,
      sections: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            __typename: PropTypes.oneOf([SECTION_TYPES.CONTENT]),
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            richContent: PropTypes.shape({
              raw: PropTypes.string.isRequired,
            }).isRequired,
          }),
          PropTypes.shape({
            __typename: PropTypes.oneOf([SECTION_TYPES.WIDGET]),
            type: PropTypes.string.isRequired,
          }),
        ])
      ).isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      }).isRequired,
      relatedArticles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        })
      ),
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string,
        }).isRequired,
        buttonText: PropTypes.string.isRequired,
        buttonLink: PropTypes.string.isRequired,
        isTelephoneNumber: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    footer: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string,
        }),
        link: PropTypes.string,
        secondaryCta: PropTypes.bool,
        arrowIcon: PropTypes.bool,
      }),
    }),
    guide: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    nextArticleInGuide: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    }),
  }).isRequired,
}

export const ContentSectionFragment = graphql`
  fragment ContentSection on ContentfulContentSection {
    title
    richContent {
      references {
        ... on ContentfulRichImage {
          ...RichImage
        }
        ... on ContentfulStepsSection {
          ...StepsSection
        }
        ... on ContentfulSplashSection {
          ...SplashSection
        }
        ... on ContentfulCallout {
          ...Callout
        }
        ... on ContentfulAsset {
          ...Asset
        }
        ... on ContentfulCallToAction {
          ...CallToAction
        }
        ... on ContentfulModule {
          ...Module
        }
      }
      raw
    }
    slug
  }
`

export const ContentfulAssetFragment = graphql`
  fragment Asset on ContentfulAsset {
    contentful_id
    __typename
    file {
      url
    }
  }
`

export const ContentfulRichImageFragment = graphql`
  fragment RichImage on ContentfulRichImage {
    contentful_id
    __typename
    id
    alternativeText
    caption
    imageDesktop {
      file {
        url
      }
    }
    imageMobile {
      file {
        url
      }
    }
  }
`

export const ContentfulStepsSectionFragment = graphql`
  fragment StepsSection on ContentfulStepsSection {
    contentful_id
    __typename
    title
    content {
      childMarkdownRemark {
        html
      }
    }
    richContent {
      raw
      references {
        ... on ContentfulCallout {
          ...Callout
        }
        ... on ContentfulRichImage {
          ...RichImage
        }
        ... on ContentfulAsset {
          ...Asset
        }
        ... on ContentfulModule {
          ...Module
        }
      }
    }
    slug
    steps {
      contentful_id
      __typename
      id
      title
      slug
      timeline
      stepSummary {
        stepSummary
      }
      richContent {
        raw
        references {
          ... on ContentfulCallout {
            ...Callout
          }
          ... on ContentfulRichImage {
            ...RichImage
          }
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulModule {
            ...Module
          }
        }
      }
    }
    structuredDataHowToSteps {
      internal {
        content
      }
    }
  }
`

export const ContentfulSplashSectionFragment = graphql`
  fragment SplashSection on ContentfulSplashSection {
    contentful_id
    __typename
    title
    slug
    content {
      content
    }
    backgroundType
    cta {
      type
      link
      text
      secondaryCta
      arrowIcon
    }
    image {
      file {
        details {
          image {
            width
            height
          }
        }
        url
      }
      title
    }
    teamMember {
      name
      title
      image {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
    }
  }
`

export const ContentfulCallToActionFragment = graphql`
  fragment CallToAction on ContentfulCallToAction {
    contentful_id
    __typename
    type
    link
    text
  }
`

export const ContentfulCallout = graphql`
  fragment Callout on ContentfulCallout {
    contentful_id
    __typename
    title
    cta {
      type
      link
      text
      secondaryCta
      arrowIcon
    }
    secondCta {
      type
      link
      text
      secondaryCta
      arrowIcon
    }
    richContent {
      raw
      references {
        ... on ContentfulModule {
          ...Module
        }
      }
    }
  }
`

export const ContentfulModule = graphql`
  fragment Module on ContentfulModule {
    contentful_id
    __typename
    moduleContent
  }
`

export const query = graphql`
  query HelpArticle(
    $slug: String!
    $nextArticleInGuideSlug: String
    $guideSlug: String
  ) {
    article: contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      articleCategories {
        shortTitle
        slug
      }
      image {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        title
      }
      intro {
        intro
      }
      backgroundType
      product
      publishedAt
      reviewedAt
      updatedAt
      richContent {
        raw
        references {
          ... on ContentfulStepsSection {
            ...StepsSection
          }
          ... on ContentfulCallout {
            ...Callout
          }
          ... on ContentfulCallToAction {
            ...CallToAction
          }
          ... on ContentfulRichImage {
            ...RichImage
          }
          ... on ContentfulAsset {
            ...Asset
          }
          ... on ContentfulModule {
            ...Module
          }
        }
      }
      structuredDataMain {
        internal {
          content
        }
      }
      structuredDataAdditionalObject {
        internal {
          content
        }
      }
      sections {
        __typename
        ... on ContentfulContentSection {
          ...ContentSection
        }
        ... on ContentfulRichImage {
          ...RichImage
        }
        ... on ContentfulStepsSection {
          ...StepsSection
        }
        ... on ContentfulSplashSection {
          ...SplashSection
        }
        ... on ContentfulCallout {
          ...Callout
        }
        ... on ContentfulWidget {
          type
        }
      }
      author {
        name
        slug
        title
        image {
          file {
            url
          }
          title
        }
      }
      relatedArticles {
        id
        title
        slug
        type
      }
      cta {
        title
        description {
          description
        }
        buttonText
        buttonLink
        isTelephoneNumber
      }
      footer {
        title
        description
        cta {
          type
          link
          text
          secondaryCta
          arrowIcon
        }
        trustmarks
      }
    }
    nextArticleInGuide: contentfulArticle(
      slug: { eq: $nextArticleInGuideSlug }
    ) {
      title
      slug
      type
      image {
        file {
          url
        }
        title
      }
    }
    guide: contentfulGuide(slug: { eq: $guideSlug }) {
      title
      slug
    }
  }
`

export default HelpArticle
